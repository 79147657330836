import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Paragraph, Heading } from "grommet"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Box pad="xlarge">
      <Heading>Qui non c'è niente</Heading>
      <Paragraph size="large">
        Sei capitato nel posto sbagliato
      </Paragraph>

      <Paragraph size="large">
        Ritorna alla <a href="/">Homepage</a>
      </Paragraph>
    </Box>
  </Layout>
)

export default NotFoundPage
